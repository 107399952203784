<template>
  <div>
    <div :class="isMobile ? 'mobile-box' : 'pc-box'">
      <div class="login">
        <div class="input-group">
          <img
            class="icon"
            src="https://lesson.iapeap.com/images/%E6%89%8B%E6%9C%BA.png"
            alt=""
          />
          <div class="label">工号</div>
          <input
            class="input"
            type="text"
            placeholder="请输入工号"
            v-model="username"
          />
        </div>
        <div class="input-group">
          <img
            class="icon"
            src="https://lesson.iapeap.com/images/%E9%94%81.png"
            alt=""
          />
          <div class="label">密码</div>
          <input
            class="input"
            type="password"
            placeholder="请输入密码"
            v-model="password"
          />
        </div>
        <button class="btn-login" @click="login">登录</button>

        <div class="password" @click="gopassword">修改密码</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import axios from "axios";

export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 768;
    },
  },
  methods: {
    login() {
      if (!this.username || !this.password) {
        Toast.fail("请输入完整的账号和密码");
        return;
      }

      axios
        .post(
          // `http://192.168.1.217:8081/assess/userLogin`,

          `https://assess.eapchina.net/userLogin`,
          `userName=${encodeURIComponent(
            this.username
          )}&passWord=${encodeURIComponent(this.password)}`
        )
        .then((response) => {
          console.log(response);
          if (response.data.error == "请勿重复作答！") {
            Toast.fail(response.data.error);
            return;
          }
          if (
            response.status == 200 &&
            response.data.error !== "账号或密码错误！"
          ) {
            Toast.success("登录成功");
            localStorage.setItem("userId", response.data.userId);
            setTimeout(() => {
              this.$router.push("/CP/minsheng/guide");
            }, 1000);
          } else {
            Toast.fail(response.data.error || "登录失败，请检查账号或密码");
          }
        })
        .catch((error) => {
          if (error.response) {
            Toast.fail(
              `登录失败：${error.response.status} - ${
                error.response.data.message || "服务器错误"
              }`
            );
          } else if (error.request) {
            Toast.fail("网络错误");
          } else {
            Toast.fail(`登录失败：${error.message}`);
          }
        });
    },
    gopassword() {
      this.$router.push("/cp/minsheng/password");
    },
  },
  created() {
    // localStorage.clear();
    localStorage.removeItem("userId");
  },
};
</script>

<style scoped>
body {
  margin: 0;
  font-family: Arial, sans-serif;
}
.mobile-box,
.pc-box {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}
.mobile-box {
  background-image: url("https://lesson.iapeap.com/images/cp/wuzi.png");

  background-size: cover;
  background-position: center;
}
.pc-box {
  background-color: rgb(176, 213, 255);
}
.login {
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.input-group {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  height: 50px;
  padding: 0 10px;
}
.icon {
  width: 30px;
  height: 30px;
}
.label {
  font-size: 16px;
  color: #5c5857;
  width: 48px;
}
.input {
  border: none;
  outline: none;
  font-size: 16px;
  color: #949494;
  flex: 1;
}
.btn-login {
  background-color: rgb(74, 122, 194);
  color: #fff;
  border: none;
  width: 100%;
  height: 50px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
}

/* 移动端样式调整 */
@media (max-width: 768px) {
  .login {
    background-color: transparent;
    box-shadow: none;
  }
}
.password {
  position: absolute;
  bottom: 30px;
  right: 50px;
  color: #5c5857;
  cursor: pointer;
}
</style>
